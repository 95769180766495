import React, { useState, useEffect } from 'react'
import axios from 'axios'
import I18n from 'i18n-js/locales.js'
import { Form, Row, Col, Button, Alert, Tab, Tabs, Card, ListGroup, Modal, Spinner, Tooltip, OverlayTrigger } from 'react-bootstrap'
import SelectSingle from '../selects/SelectSingle.js'
import SelectMultiple from '../selects/SelectMultiple.js'
import ZoneForm from './ZoneForm.js'
import { SingleDatePicker } from 'react-dates'
import moment from 'moment-timezone';
import { appConstants, localTimezone, facilitySectors, getIpOutputsData } from 'utils/constants.js'
import { formatValidationErrors } from 'utils/format.js'
import { BiPlus } from 'react-icons/bi'
import { BiLinkExternal } from 'react-icons/bi'
import { BsFileEarmarkCheck } from "react-icons/bs";
import { BsInfoCircleFill } from "react-icons/bs";
import VisitResourceTable from '../tables/VisitResourceTable'
import ResourceForm from '../forms/ResourceForm.js'
import Comments from '../Comments.js'
import ExportIpOutputs from './ExportIpOutputs.js';


function lastMonth() {
  const date = new Date()
  date.setDate(17)
  return new Date(date.setMonth(date.getMonth() - 1))
}

export default function SiteVisitForm(props) {
  const [id, setId] = useState(props.id || '')
  const [description, setDescription] = useState('')
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [dateSubmitted, setDateSubmitted] = useState(null)
  const [reportingDate, setReportingDate] = useState(lastMonth())
  const [officeId, setOfficeId] = useState('')
  const [activityId, setActivityId] = useState('')
  const [governorateId, setGovernorateId] = useState('')
  const [districtId, setDistrictId] = useState('')
  const [territoryId, setTerritoryId] = useState('')
  const [zoneId, setZoneId] = useState('')
  const [sectorId, setSectorId] = useState('')
  const [monitoringMonthId, setMonitoringMonthId] = useState('')
  const [virtual, setVirtual] = useState(false)
  const [visitType, setVisitType] = useState('')
  const [visitPurpose, setVisitPurpose] = useState('')
  const [repeatVisit, setRepeatVisit] = useState(false)
  const [latitude, setLatitude] = useState('')
  const [longitude, setLongitude] = useState('')
  const [requestStatus, setRequestStatus] = useState('created')
  const [requestJustification, setRequestJustification] = useState('')
  const [requestJustificationDetails, setRequestJustificationDetails] = useState('')
  const [subsectorIds, setSubsectorIds] = useState([])
  const [agreementIds, setAgreementIds] = useState([])
  const [fulcrumData, setFulcrumData] = useState({})
  const [monitoringCycleId, setMonitoringCycleId] = useState('')
  const [quarterId, setQuarterId] = useState('')
  const [reportDue, setReportDue] = useState(null)
  const [sentToIp, setSentToIp] = useState(null)
  const [ipFeedbackDue, setIpFeedbackDue] = useState(null)
  const [ipFeedbackReceived, setIpFeedbackReceived] = useState(false)
  const [ipComments, setIpComments] = useState('')
  const [ipCommentsExist, setIpCommentsExist] = useState(false)
  const [ipCommentsUpdated, setIpCommentsUpdated] = useState(null)
  const [ipCommentsUpdater, setIpCommentsUpdater] = useState('')
  const [resourcesCount, setResourcesCount] = useState(0)
  const [partner, setPartner] = useState('')
  const [ipSiteActive, setIpSiteActive] = useState(true)
  const [ipSiteInactiveReason, setIpSiteInactiveReason] = useState('')
  const [ipLiasonName, setIpLiasonName] = useState('')
  const [ipLiasonContact, setIpLiasonContact] = useState('')
  const [ipLiasonPhone, setIpLiasonPhone] = useState('')
  const [communityLeaderNameOne, setCommunityLeaderNameOne] = useState('')
  const [communityLeaderContactOne, setCommunityLeaderContactOne] = useState('')
  const [communityLeaderNameTwo, setCommunityLeaderNameTwo] = useState('')
  const [communityLeaderContactTwo, setCommunityLeaderContactTwo] = useState('')
  const [communityVolunteerNameOne, setCommunityVolunteerNameOne] = useState('')
  const [communityVolunteerContactOne, setCommunityVolunteerContactOne] = useState('')
  const [communityVolunteerNameTwo, setCommunityVolunteerNameTwo] = useState('')
  const [communityVolunteerContactTwo, setCommunityVolunteerContactTwo] = useState('')
  const [ipTabIpComments, setIpTabIpComments] = useState('')
  const [smlp2DataReviewed, setSmlp2DataReviewed] = useState(false)
  const [smlp2IssueIdentified, setSmlp2IssueIdentified] = useState(false)
  const [smlp2MonitoringIssue, setSmlp2MonitoringIssue] = useState('')
  const [smlp2OtherIssues, setSmlp2OtherIssues] = useState('')
  const [smlp2ActionPoint, setSmlp2ActionPoint] = useState('')
  const [smlp2ReviewComments, setSmlp2ReviewComments] = useState('')
  const [smlp2IssueFixed, setSmlp2IssueFixed] = useState(false)
  const [smlp2DateFixed, setSmlp2DateFixed] = useState(null)
  const [weight, setWeight] = useState(1)
  const [wfpDescription, setWfpDescription] = useState('')
  const [createDuplicate, setCreateDuplicate] = useState(false)
  const [siteAddress, setSiteAddress] = useState('')
  const [flashReportReady, setFlashReportReady] = useState(false)
  const [flashReportStatus, setFlashReportStatus] = useState('')
  const [ipFlashComments, setIpFlashComments] = useState('')
  const [ipFlashCommentsUpdated, setIpFlashCommentsUpdated] = useState(null)
  const [ipFlashCommentsUpdater, setIpFlashCommentsUpdater] = useState('')
  const [hasFlashReport, setHasFlashReport] = useState(false)
  const [facilityType, setFacilityType] = useState('')
  const [hasFacilitySector, setHasFacilitySector] = useState(false)
  const [facilitySectorIds, setFacilitySectorIds] = useState([])
  const [discussionClosed, setDiscussionClosed] = useState(false)
  const [currentAwardEndDate, setCurrentAwardEndDate] = useState(null)
  const [podioId, setPodioId] = useState('')
  const [reasonForSelection, setReasonForSelection] = useState('')
  // IP Outputs fields
  const [sectorActivitiesFunded, setSectorActivitiesFunded] = useState(false)
  const [ipSubRecipient, setIpSubRecipient] = useState('')
  const [supportedFacilities, setSupportedFacilities] = useState('')
  const [typeOfSupport, setTypeOfSupport] = useState('')
  const [cashForWork, setCashForWork] = useState('')
  const [staffBeingTrained, setStaffBeingTrained] = useState('')
  const [typesOfDistributions, setTypesOfDistributions] = useState('')
  const [numberOfDistributionsPerBeneficiary, setNumberOfDistributionsPerBeneficiary] = useState('')
  const [valuePerDistribution, setValuePerDistribution] = useState('')
  const [numberOfTargetedBeneficiariesDist, setNumberOfTargetedBeneficiariesDist] = useState('')
  const [servicesOfferedToBen, setServicesOfferedToBen] = useState('')
  const [numberOfBeneficiariesTargetedServ, setNumberOfBeneficiariesTargetedServ] = useState('')
  const [typeOfFormalTraining, setTypeOfFormalTraining] = useState('')
  const [otherActivities, setOtherActivities] = useState('')
  const [coordinatorDetails, setCoordinatorDetails] = useState('')
  const [alternateCoordinator, setAlternateCoordinator] = useState('')
  const [anticipatedDates, setAnticipatedDates] = useState('')

  const [alertMessage, setAlertMessage] = useState('')
  const [tabKey, setTabKey] = useState(props.tabKey || 'sitevisit')
  const [updated, setUpdated] = useState(false)
  const [deleted, setDeleted] = useState(false)
  const [startFocused, setStartFocused] = useState(false)
  const [reportDueFocused, setReportDueFocused] = useState(false)
  const [sentToIpFocused, setSentToIpFocused] = useState(false)
  const [ipFeedbackDueFocused, setIpFeedbackDueFocused] = useState(false)
  const [smlp2FixedFocused, setSmlp2FixedFocused] = useState(false)
  const [currentAwardEndDateFocused, setCurrentAwardEndDateFocused] = useState(false)
  const [showAddZoneModal, setShowAddZoneModal] = useState(false)
  const [showAddResourceModal, setShowAddResourceModal] = useState(false)
  const [resourceModalTitle, setResourceModalTitle] = useState(I18n.t('resource.add'))
  const [resourceModalTypeId, setResourceModalTypeId] = useState('')
  const [isWfp, setIsWfp] = useState(false)
  const [podioSending, setPodioSending] = useState(false)
  const [saveInProgress, setSaveInProgress] = useState(false)

  useEffect(() => {
    if (tabKey === "fulcrum" && Object.keys(fulcrumData).length === 0) {
      const fetchData = async () => {
        const response = await axios.get(`${appConstants.FORMS_FOR_VISIT_URL}/${props.id}`)
        setFulcrumData(response.data || {})
      }
      fetchData()
    }
  }, [tabKey])

  useEffect(() => {
    if (tabKey === "report" && !hasFlashReport) {
      const fetchData = async () => {
        const response = await axios.get(`${appConstants.MONITORING_EVENT_URL}${props.id}.json`)
        setHasFlashReport(response.data.has_flash_report)
      }
      fetchData()
    }
  })

  useEffect(() => {
    if (updated) {
      props.refreshData()
      if (ipComments !== "") {
        setIpCommentsExist(true)
      }
      setUpdated(false)
    }
    if (deleted) {
      props.refreshData()
      setDeleted(false)
      props.closeModal()
    }
  }, [updated, deleted])

  useEffect(() => {
    if (createDuplicate && !id) {
      setCreateDuplicate(false)
      handleSubmit()
    }
  }, [createDuplicate])

  useEffect(() => {
    const fetchSectors = async () => {
      const response = await axios.get(`${appConstants.SECTORS_URL}`)
      const fac = response.data ? response.data.filter(s => facilitySectors.indexOf(s.name.trim()) != -1).map(s => s.id) : []
      setFacilitySectorIds(fac)
    }
    fetchSectors()
  }, [props.id])

  useEffect(() => {
    const hasFacilitySector = facilitySectorIds.indexOf(sectorId) != -1 ? true : false
    setHasFacilitySector(hasFacilitySector)
  }, [sectorId])

  useEffect(() => {
    if (activityId == props.wfpId) {
      setIsWfp(true)
    } else {
      setIsWfp(false)
    }
  }, [activityId])

  useEffect(() => {
    if (props.id && !resourcesCount && !showAddResourceModal) {
      const fetchData = async () => {
        const response = await axios.get(`${appConstants.MONITORING_EVENT_URL}/${props.id}.json`)
        setResourcesCount(response.data.get_resources_count)
        setDescription(response.data.data.description || '')
        setStartDate(response.data.start_date ? moment(response.data.start_date) : null)
        setEndDate(response.data.end_date ? moment(response.data.end_date) : null)
        setDateSubmitted(response.data.date_submitted ? moment(response.data.date_submitted) : null)
        setReportingDate(response.data.reporting_date ? new Date(response.data.reporting_date) : lastMonth())
        setOfficeId(response.data.office_id || '')
        setActivityId(response.data.activity_id || '')
        setGovernorateId(response.data.governorate ? response.data.governorate.id : '')
        setDistrictId(response.data.district ? response.data.district.id : '')
        setTerritoryId(response.data.territory ? response.data.territory.id : '')
        setZoneId(response.data.zone_id || '')
        setSectorId(response.data.sector_id || '')
        setMonitoringMonthId(response.data.monitoring_month_id || '')
        setVirtual(response.data.virtual || false)
        setVisitType(response.data.visit_type || 'in_person')
        setVisitPurpose(response.data.visit_purpose || 'tpm')
        setRepeatVisit(response.data.repeat_visit || false)
        setLatitude(response.data.latitude || 0)
        setLongitude(response.data.longitude || 0)
        setRequestStatus(response.data.request_status || 'created')
        setRequestJustification(response.data.request_justification || '')
        setRequestJustificationDetails(response.data.data.request_justification_details || '')
        setSubsectorIds(response.data.subsectors ? response.data.subsectors.map(s => s.id) : [])
        setAgreementIds(response.data.agreements ? response.data.agreements.map(a => a.id) : [])
        setSiteAddress(response.data.site_address || '')
        setMonitoringCycleId(response.data.monitoring_cycle_id || '')
        setQuarterId(response.data.quarter_id || '')
        setReportDue(response.data.report_due ? moment(response.data.report_due) : null)
        setSentToIp(response.data.sent_to_ip ? moment(response.data.sent_to_ip) : null)
        setIpFeedbackDue(response.data.ip_feedback_due ? moment(response.data.ip_feedback_due) : null)
        setIpFeedbackReceived(response.data.ip_feedback_received || false)
        setIpComments(response.data.ip_comments || '')
        setIpCommentsExist(response.data.ip_comments && response.data.ip_comments.length > 0)
        setIpCommentsUpdated(response.data.ip_comments_updated || null)
        setIpCommentsUpdater(response.data.get_ip_comments_updater || '')
        setPartner(response.data.partner || '')
        setIpSiteActive(response.data.ip_site_active || false)
        setIpSiteInactiveReason(response.data.ip_site_inactive_reason || '')
        setIpLiasonName(response.data.ip_liason_name || '')
        setIpLiasonContact(response.data.ip_liason_contact || '')
        setIpLiasonPhone(response.data.ip_liason_phone || '')
        setCommunityLeaderNameOne(response.data.community_leader_name_1 || '')
        setCommunityLeaderContactOne(response.data.community_leader_contact_1 || '')
        setCommunityLeaderNameTwo(response.data.community_leader_name_2 || '')
        setCommunityLeaderContactTwo(response.data.community_leader_contact_2 || '')
        setCommunityVolunteerNameOne(response.data.community_volunteer_name_1 || '')
        setCommunityVolunteerContactOne(response.data.community_volunteer_contact_1 || '')
        setCommunityVolunteerNameTwo(response.data.community_volunteer_name_2 || '')
        setCommunityVolunteerContactTwo(response.data.community_volunteer_contact_2 || '')
        setIpTabIpComments(response.data.ip_tab_ip_comments || '')
        setSmlp2DataReviewed(response.data.smlp2_data_reviewed || false)
        setSmlp2IssueIdentified(response.data.smlp2_issue_identified || false)
        setSmlp2MonitoringIssue(response.data.smlp2_monitoring_issue || null)
        setSmlp2OtherIssues(response.data.smlp2_other_issues || '')
        setSmlp2ActionPoint(response.data.smlp2_action_point || '')
        setSmlp2ReviewComments(response.data.smlp2_review_comments || '')
        setSmlp2IssueFixed(response.data.smlp2_issue_fixed || false)
        setSmlp2DateFixed(response.data.smlp2_date_fixed ? moment(response.data.smlp2_date_fixed) : null)
        setFlashReportReady(response.data.flash_report_ready || false)
        setFlashReportStatus(response.data.flash_report_status || null)
        setIpFlashComments(response.data.ip_flash_comments || '')
        setIpFlashCommentsUpdated(response.data.ip_flash_comments_updated || null)
        setIpFlashCommentsUpdater(response.data.get_ip_flash_comments_updater || '')
        setHasFlashReport(response.data.has_flash_report || false)
        setWeight(response.data.weight || '')
        setWfpDescription(response.data.data.wfp_description || '')
        setFacilityType(response.data.facility_type || '')
        setHasFacilitySector(response.data.has_facility_sector || false)
        setDiscussionClosed(response.data.discussion_closed || false)
        setCurrentAwardEndDate(response.data.current_award_end_date ? moment(response.data.current_award_end_date) : null)
        setIsWfp(props.wfpId == response.data.activity_id)
        setPodioId(response.data.podio_id || '')
        setReasonForSelection(response.data.data.reason_for_selection || '')
        // IP Outputs fields
        setSectorActivitiesFunded(response.data.data.sector_activities_funded === 'true' ? true : false)
        setIpSubRecipient(response.data.data.ip_sub_recipient || '')
        setSupportedFacilities(response.data.data.supported_facilities || '')
        setTypeOfSupport(response.data.data.type_of_support || '')
        setCashForWork(response.data.data.cash_for_work || '')
        setStaffBeingTrained(response.data.data.staff_being_trained || '')
        setTypesOfDistributions(response.data.data.types_of_distributions || '')
        setNumberOfDistributionsPerBeneficiary(response.data.data.number_of_distributions_per_beneficiary || '')
        setValuePerDistribution(response.data.data.value_per_distribution || '')
        setNumberOfTargetedBeneficiariesDist(response.data.data.number_of_targeted_beneficiaries_dist || '')
        setServicesOfferedToBen(response.data.data.services_offered_to_ben || '')
        setNumberOfBeneficiariesTargetedServ(response.data.data.number_of_beneficiaries_targeted_serv || '')
        setTypeOfFormalTraining(response.data.data.type_of_formal_training || '')
        setOtherActivities(response.data.data.other_activities || '')
        setCoordinatorDetails(response.data.data.coordinator_details || '')
        setAlternateCoordinator(response.data.data.alternate_coordinator || '')
        setAnticipatedDates(response.data.data.anticipated_dates || '')
      }

      fetchData()
    }
  }, [props.id, showAddResourceModal])

  const createFormData = function()  {
    let formData = new FormData()
    formData.append('monitoring_request[start_date]', startDate) 
    formData.append('monitoring_request[end_date]', endDate)
    formData.append('monitoring_request[date_submitted]', dateSubmitted)
    formData.append('monitoring_request[request_justification]', requestJustification)
    formData.append('monitoring_request[request_justification_details]', requestJustificationDetails)
    formData.append('monitoring_request[virtual]', virtual || false)
    formData.append('monitoring_request[visit_type]', visitType || "")
    formData.append('monitoring_request[visit_purpose]', visitPurpose || "")
    formData.append('monitoring_request[repeat_visit]', repeatVisit || false)
    formData.append('monitoring_request[latitude]', latitude || 0)
    formData.append('monitoring_request[longitude]', longitude || 0)
    formData.append('monitoring_request[request_status]', requestStatus)
    formData.append('monitoring_request[office_id]', officeId || "")
    formData.append('monitoring_request[activity_id]', activityId || "")
    formData.append('monitoring_request[district_id]', districtId || "")
    formData.append('monitoring_request[territory_id]', territoryId || "")
    formData.append('monitoring_request[zone_id]', zoneId || "")
    formData.append('monitoring_request[sector_id]', sectorId || "")
    formData.append('monitoring_request[monitoring_month_id]', monitoringMonthId || "")
    formData.append('monitoring_request[reporting_date]', reportingDate || "")
    formData.append('monitoring_request[site_address]', siteAddress || "")
    formData.append('monitoring_request[monitoring_cycle_id]', monitoringCycleId || "")
    formData.append('monitoring_request[quarter_id]', quarterId || "")
    formData.append('monitoring_request[report_due]', reportDue || "")
    formData.append('monitoring_request[sent_to_ip]', sentToIp || "")
    formData.append('monitoring_request[ip_feedback_due]', ipFeedbackDue || "")
    formData.append('monitoring_request[ip_feedback_received]', ipFeedbackReceived || false)
    formData.append('monitoring_request[ip_comments]', ipComments || "")
    formData.append('monitoring_request[partner]', partner || "")
    formData.append('monitoring_request[ip_site_active]', ipSiteActive || false)
    formData.append('monitoring_request[ip_site_inactive_reason]', ipSiteInactiveReason || "")
    formData.append('monitoring_request[ip_liason_name]', ipLiasonName || "")
    formData.append('monitoring_request[ip_liason_contact]', ipLiasonContact || "")
    formData.append('monitoring_request[ip_liason_phone]', ipLiasonPhone || "")
    formData.append('monitoring_request[community_leader_name_1]', communityLeaderNameOne || "")
    formData.append('monitoring_request[community_leader_contact_1]', communityLeaderContactOne || "")
    formData.append('monitoring_request[community_leader_name_2]', communityLeaderNameTwo || "")
    formData.append('monitoring_request[community_leader_contact_2]', communityLeaderContactTwo || "")
    formData.append('monitoring_request[community_volunteer_name_1]', communityVolunteerNameOne || "")
    formData.append('monitoring_request[community_volunteer_contact_1]', communityVolunteerContactOne || "")
    formData.append('monitoring_request[community_volunteer_name_2]', communityVolunteerNameTwo || "")
    formData.append('monitoring_request[community_volunteer_contact_2]', communityVolunteerContactTwo || "")
    formData.append('monitoring_request[ip_tab_ip_comments]', ipTabIpComments || "")
    formData.append('monitoring_request[smlp2_data_reviewed]', smlp2DataReviewed || false)
    formData.append('monitoring_request[smlp2_issue_identified]', smlp2IssueIdentified || false)
    if (smlp2MonitoringIssue) {formData.append('monitoring_request[smlp2_monitoring_issue]', smlp2MonitoringIssue)}
    formData.append('monitoring_request[smlp2_other_issues]', smlp2OtherIssues || "")
    formData.append('monitoring_request[smlp2_action_point]', smlp2ActionPoint || "")
    formData.append('monitoring_request[smlp2_review_comments]', smlp2ReviewComments || "")
    formData.append('monitoring_request[smlp2_issue_fixed]', smlp2IssueFixed || false)
    formData.append('monitoring_request[smlp2_date_fixed]', smlp2DateFixed || "")
    formData.append('monitoring_request[flash_report_ready]', flashReportReady || false)
    if (flashReportStatus) {formData.append('monitoring_request[flash_report_status]', flashReportStatus)}
    formData.append('monitoring_request[ip_flash_comments]', ipFlashComments || "")
    formData.append('monitoring_request[weight]', weight || "")
    formData.append('monitoring_request[wfp_description]', wfpDescription || "")
    formData.append('monitoring_request[facility_type]', facilityType || "")
    formData.append('monitoring_request[discussion_closed]', discussionClosed || false)
    formData.append('monitoring_request[current_award_end_date]', currentAwardEndDate || "")
    formData.append('monitoring_request[reason_for_selection]', reasonForSelection || "")

    subsectorIds.forEach(s => {
      formData.append('monitoring_request[subsector_ids][]', s)
    })
    agreementIds.forEach(a => {
      formData.append('monitoring_request[agreement_ids][]', a)
    })

    // IP Outputs fields
    formData.append('monitoring_request[sector_activities_funded]', sectorActivitiesFunded)
    formData.append('monitoring_request[ip_sub_recipient]', ipSubRecipient || "")
    formData.append('monitoring_request[supported_facilities]', supportedFacilities || "")
    formData.append('monitoring_request[type_of_support]', typeOfSupport || "")
    formData.append('monitoring_request[cash_for_work]', cashForWork || "")
    formData.append('monitoring_request[staff_being_trained]', staffBeingTrained || "")
    formData.append('monitoring_request[types_of_distributions]', typesOfDistributions || "")
    formData.append('monitoring_request[number_of_distributions_per_beneficiary]', numberOfDistributionsPerBeneficiary || "")
    formData.append('monitoring_request[value_per_distribution]', valuePerDistribution || "")
    formData.append('monitoring_request[number_of_targeted_beneficiaries_dist]', numberOfTargetedBeneficiariesDist || "")
    formData.append('monitoring_request[services_offered_to_ben]', servicesOfferedToBen || "")
    formData.append('monitoring_request[number_of_beneficiaries_targeted_serv]', numberOfBeneficiariesTargetedServ || "")
    formData.append('monitoring_request[type_of_formal_training]', typeOfFormalTraining || "")
    formData.append('monitoring_request[other_activities]', otherActivities || "")
    formData.append('monitoring_request[coordinator_details]', coordinatorDetails || "")
    formData.append('monitoring_request[alternate_coordinator]', alternateCoordinator || "")
    formData.append('monitoring_request[anticipated_dates]', anticipatedDates || "")

    return formData
  }

  const handleSubmit = async () => {
    setSaveInProgress(true)
    axios.defaults.headers.common["X-CSRF-TOKEN"] = props.authenticityToken;
    const data = createFormData()
    if (data.get('monitoring_request[sector]') && (data.get('monitoring_request[sector]').downcase == 'nutrition' || data.get('monitoring_request[sector]').downcase == 'health') && !data.get('monitoring_request[facility_type]')) {
      setAlertMessage(I18n.t('activerecord.errors.models.monitoring_request.attributes.facility_type.blank'))
      setSaveInProgress(false)
      return
    }
    let notice = id ? I18n.t("notices.update_success") : I18n.t("notices.create_success")
    if (id) {
      await axios.put(`${appConstants.MONITORING_EVENT_URL}${id}`, data )
      .then(res => {
        setIpCommentsUpdated(res.data.ip_comments_updated)
        setIpCommentsUpdater(res.data.get_ip_comments_updater)
        setIpFlashCommentsUpdated(res.data.ip_flash_comments_updated)
      })
      .catch(err => {
        notice = formatValidationErrors('monitoring_request', err.response.data)
      })
    } else {
      await axios.post(appConstants.MONITORING_EVENT_URL, data )
      .then(res => {
        setIpCommentsUpdated(res.data.ip_comments_updated)
        setIpCommentsUpdater(res.data.get_ip_comments_updater)
        setIpFlashCommentsUpdated(res.data.ip_flash_comments_updated)
      })
      .catch(err => {
        notice = formatValidationErrors('monitoring_request', err.response.data)
      })
    }
    setAlertMessage(notice)
    setUpdated(true)
    setSaveInProgress(false)
  }

  const handleDelete = async () => {
    axios.defaults.headers.common["X-CSRF-TOKEN"] = props.authenticityToken;
    const res = await axios.delete(`${appConstants.MONITORING_EVENT_URL}/${id}.json`)
    const notice = res.status === 204 ? I18n.t("notices.delete_success") : I18n.t("notices.delete_failure")
    setAlertMessage(notice)
    setDeleted(true)
  }

  const handleSendUpdateToPodio = async () => {
    setPodioSending(true)
    axios.defaults.headers.common["X-CSRF-TOKEN"] = props.authenticityToken;
    let notice = I18n.t("notices.podio_update_success")
    await axios.put(`${appConstants.UPDATE_PODIO_VISIT_URL}/${id}`)
    .then(res => {
      setPodioSending(false)
    })
    .catch(error => {
      setPodioSending(false)
      notice = I18n.t("notices.podio_update_failure")
      console.log(error)
    })
    setAlertMessage(notice)
    setUpdated(true)
  }

  const renderTooltip = (msg) => (
    <Tooltip id="info-tooltip">
      <span className='email-guidance-tooltip' dangerouslySetInnerHTML={{__html: I18n.t(`ip_outputs_request.guidance.${msg}`)}}></span>
    </Tooltip>
  )

  const renderInfoIcon = (msg) => {
    return (
      <OverlayTrigger
        placement="right"
        delay={{ show: 250, hide: 400 }}
        overlay={renderTooltip(msg)}>
        <Button variant="link" className='p-0 mb-1'><BsInfoCircleFill /></Button>
      </OverlayTrigger>
    )
  }

  const showSpecificResourceModal = (type) => {
    setResourceModalTitle(I18n.t(`resource.add_a_${type}`))
    setResourceModalTypeId(props.resourceTypeIds[type])
    setShowAddResourceModal(true)
  }

  const renderAlertMessage = () => {
    return alertMessage ? (
      <Alert variant={'info'} onClose={() => setAlertMessage("")} dismissible>
        {alertMessage}
      </Alert>
    ) : ""
  }

  const renderAddZoneButton = () => {
    return territoryId && props.allowed.edit_site_visit ? (
      <Button variant="outline-secondary" onClick={() => setShowAddZoneModal(true)} className="add-collection-btn" title={I18n.t('zone.add')}><BiPlus /></Button>
    ) : ""
  }

  const closeAddZoneModal = (z) => {
    setShowAddZoneModal(false)
    setZoneId(z)
    setAlertMessage("")
  }

  const renderUpdatePodioButton = () => {
    return id && podioId && props.allowed.create_site_visit ? (
      <Button disabled={!props.allowed.create_site_visit || saveInProgress} variant="outline-secondary" onClick={() => handleSendUpdateToPodio()}>{podioSending ? <><Spinner animation="border" size="sm" /> Sending...</> : I18n.t("navigation.update_podio_visit")}</Button>
    ) : ""
  }

  const renderDuplicateButton = () => {
    if (id && props.allowed.create_site_visit) {
      return (<Button disabled={!props.allowed.create_site_visit} variant="outline-primary" onClick={() => {setId(""); setCreateDuplicate(true)}}>{I18n.t("navigation.duplicate")}</Button> )
    }
  }

  const renderDeleteButton = () => {
    if (id && props.allowed.create_site_visit) {
      return (<Button disabled={!props.allowed.create_site_visit} variant="outline-danger" style={{float: 'right'}} onClick={() => handleDelete()}>{I18n.t("navigation.delete")}</Button>)
    }
  }

  const renderCancelButton = () => {
    return (<Button variant="outline-secondary" style={{float: 'right'}} onClick={() => props.closeModal()}>{I18n.t("navigation.cancel")}</Button>)
  }

  const renderFlashReportUploaded = () => {
    if (hasFlashReport) {
      return (<span className="float-start darkorange mt-4 h4"><BsFileEarmarkCheck /></span>)
    }
  }

  const renderFulcrumTab = () => {
    return props.allowed.fulcrum_tab ? (
      <Tab eventKey="fulcrum" title={I18n.t('navigation.fulcrum_tab')} className="mb-3">
          <Row className="mt-3">
            { Object.keys(fulcrumData).map(k =>
                <Card key={k} className="mt-3">
                  <Card.Body>
                    <Card.Title>{fulcrumData[k].name} - {fulcrumData[k].records.length} <a href={appConstants.FULCRUM_URL + "apps/" + k} target="_blank" rel="noopener noreferrer"><BiLinkExternal /></a></Card.Title>
                    <ListGroup variant="flush">
                      { fulcrumData[k].records.map(r =>
                          <ListGroup.Item key={r.id}><a href={appConstants.FULCRUM_URL + "records/" + r.id} target="_blank" rel="noopener noreferrer">{ r.title ? r.title : r.id } - { r.status }</a></ListGroup.Item>
                        )
                      }
                    </ListGroup>
                  </Card.Body>
                </Card>
              )
            }
          </Row>
        </Tab>
    ) : ""
  }

  const renderCloseDiscussionSwitch = () => {
    return id && props.allowed.create_site_visit ? (
      <div className='float-end label-wrap'>
        <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.discussion_closed')}&nbsp;&nbsp;</Form.Label>
          <Form.Check
            type="switch"
            name="discussionClosed"
            checked={discussionClosed}
            value={false}
            onChange={() => setDiscussionClosed(!discussionClosed)}
            bsPrefix="discussionClosed-switch" />
      </div>
    ) : id && discussionClosed ? <span className="fst-italic fw-lighter">&nbsp;&nbsp;({I18n.t('activerecord.attributes.monitoring_request.discussion_closed')})</span> : ""
  }

  const renderAddResourceModal = () => {
    return (
      <Modal
        show={showAddResourceModal}
        onHide={()=>setShowAddResourceModal(false)}
        keyboard={true}
        size={'xl'}>
        <Modal.Header closeButton>
          <Modal.Title>{resourceModalTitle || I18n.t('resource.add_a_resource')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ResourceForm
            authenticityToken={props.authenticityToken}
            isModal={true}
            closeModal={()=>[setShowAddResourceModal(false), setResourceModalTitle(""), setResourceModalTypeId("")]}
            resource={{monitoring_request_id: parseInt(id), activities: [{id: activityId}], office_id: officeId, monitoring_cycle_id: monitoringCycleId, resource_type_id: resourceModalTypeId || "", tags: [], collections: []}}
          />
        </Modal.Body>
      </Modal>
    )
  }

  return showAddZoneModal ? (
    <ZoneForm
      authenticityToken={props.authenticityToken}
      zone={{territory_id: territoryId}}
      isModal={true}
      closeModal={closeAddZoneModal} />
  ) : (
    <Form>
      { renderAddResourceModal() }
      <Row>
        <Col>
          {renderAlertMessage()}
        </Col>
      </Row>
      <Tabs activeKey={tabKey} id="sitevisit-tabs" onSelect={(k) => setTabKey(k)}>
        <Tab eventKey="sitevisit" title={I18n.t('navigation.site_visit_tab')} className="mb-3">
          <Row className="mt-3">
            <Form.Group as={Col} md={4} className="mb-3" controlId="requestForm.visit_purpose">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.visit_purpose')}<span className="text-danger">*</span></Form.Label>
              <SelectSingle
                isDisabled={!props.allowed.edit_site_visit_meta}
                selected={visitPurpose}
                onValueChange={(e) => setVisitPurpose(e ? e.value : "")}
                url={appConstants.VISIT_PURPOSES_URL}
                isClearable={false}
                placeholder={I18n.t('selects.single')} />
            </Form.Group>

            <Form.Group as={Col} md={4} controlid="requestForm.activity">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.activity')}</Form.Label>
              <SelectSingle
                isDisabled={!props.allowed.edit_site_visit}
                selected={activityId}
                onValueChange={(e) => setActivityId(e ? e.value : "")}
                url={appConstants.ACTIVITIES_URL + ".json"}
                isClearable={true}
                placeholder={I18n.t('selects.single')} />
            </Form.Group>

            <Form.Group as={Col} md={2} className="mb-3" controlid="requestForm.visit_weight">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.weight')}</Form.Label>
              <Form.Control
                disabled={!props.allowed.edit_site_visit}
                name="weight"
                type="number"
                value={weight}
                onChange={(e) => setWeight(e.target.value)} />
            </Form.Group>

            { props.allowed.reason_for_selection ? (
              <Form.Group as={Col} md={4} className="mb-3" controlid="requestForm.reason_for_selection">
                <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.reason_for_selection')}</Form.Label>
                <Form.Control
                  disabled={!props.allowed.reason_for_selection}
                  name="reasonForSelection"
                  as="textarea"
                  rows="3"
                  value={reasonForSelection}
                  onChange={(e) => setReasonForSelection(e.target.value)} />
              </Form.Group>
            ) : "" }

            <Form.Group as={Col} md={4} className="mb-3" controlid="requestForm.agreements">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.agreements')}</Form.Label>
              <SelectMultiple
                isDisabled={!props.allowed.edit_site_visit}
                selected={agreementIds}
                onChange={(e) => setAgreementIds(e ? e.map(i => i.value) : [])}
                url={appConstants.AGREEMENTS_URL + ".json"}
                isClearable={true}
                placeholder={I18n.t('selects.multiple_optional')} />
            </Form.Group>

            <Form.Group as={Col} md={2} className="mb-3" controlid="requestForm.current_award_end_date">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.current_award_end_date')}</Form.Label><br/>
              <SingleDatePicker
                disabled={!props.allowed.edit_site_visit}
                date={currentAwardEndDate}// momentPropTypes.momentObj or null
                onDateChange={current_award_end_date => setCurrentAwardEndDate( current_award_end_date )} // PropTypes.func.isRequired
                focused={currentAwardEndDateFocused} // PropTypes.bool
                onFocusChange={({ focused: current_award_end_date_focused }) => setCurrentAwardEndDateFocused( current_award_end_date_focused )} // PropTypes.func.isRequired
                id="current_award_end_date" // PropTypes.string.isRequired,
                isOutsideRange={()=>false}
              />
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} md={4} className="mb-3" controlid="requestForm.sector">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.sector')}</Form.Label>
              <SelectSingle
                isDisabled={!props.allowed.edit_site_visit}
                selected={sectorId}
                onValueChange={(e) => setSectorId(e ? e.value : "")}
                url={appConstants.SECTORS_URL}
                placeholder={I18n.t('selects.single')}
                isClearable={true} />
            </Form.Group>

            { subsectorIds.length > 0 ?
              <Form.Group as={Col} md={8} className="mb-3" controlid="requestForm.subsector">
                <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.subsectors')}</Form.Label>
                <SelectMultiple
                  isDisabled={true}
                  selected={subsectorIds}
                  onChange={(e) => setSubsectorIds(e ? e.map(i => i.value) : [])}
                  url={appConstants.SUBSECTORS_URL + "?sector_id=" + (sectorId || -1)}
                  placeholder={I18n.t('selects.multiple_optional')}
                  groupedName='subsectors'
                  isDependent={true} />
              </Form.Group>
            : "" }
          </Row>

          <Row>
            <Form.Group as={Col} md={4} className="mb-3" controlid="requestForm.district">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.district')}</Form.Label>
              <SelectSingle
                isDisabled={!props.allowed.edit_site_visit}
                selected={districtId}
                onValueChange={(e) => {
                  setDistrictId(e ? e.value : "")
                  setTerritoryId("")
                  setZoneId("")
                }}
                url={appConstants.DISTRICTS_URL}
                placeholder={I18n.t('selects.single')}
                isClearable={true} />
            </Form.Group>

            <Form.Group as={Col} md={4} className="mb-3" controlid="requestForm.territory">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.territory')}</Form.Label>
              <SelectSingle
                isDisabled={!props.allowed.edit_site_visit}
                selected={territoryId}
                onValueChange={(e) => {
                  setTerritoryId(e ? e.value : "")
                  setZoneId("")
                }}
                url={appConstants.TERRITORIES_BY_DISTRICT_URL + (districtId || -1) + ".json"}
                placeholder={I18n.t('selects.single')}
                isClearable={true}
                isDependent={true} />
            </Form.Group>

            <Form.Group as={Col} md={4} className="mb-3" controlid="requestForm.zone">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.zone')}</Form.Label> &nbsp;
              {renderAddZoneButton()}
              <SelectSingle
                isDisabled={!props.allowed.edit_site_visit}
                selected={zoneId}
                onValueChange={(e) => setZoneId(e ? e.value : "")}
                url={appConstants.ZONES_BY_TERRITORY_URL + (territoryId || -1) + ".json"}
                placeholder={I18n.t('selects.single')}
                isClearable={true}
                isDependent={true} />
            </Form.Group>
          </Row>
          
          <Row>
            <Form.Group as={Col} md={4} className="mb-3" controlid="requestForm.site_address">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.site_address')}</Form.Label>
                <Form.Control 
                  disabled={!props.allowed.edit_site_visit}
                  name="siteAddress"
                  type="text"
                  value={siteAddress}
                  onChange={(e) => setSiteAddress(e.target.value)} />
            </Form.Group>

            <Form.Group as={Col} md={2} className="mb-3" controlId="requestForm.latitude">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.latitude')}</Form.Label>
              <Form.Control
                disabled={!props.allowed.edit_site_visit}
                name="latitude"
                type="text"
                value={latitude}
                onChange={(e) => setLatitude(e.target.value)} />
            </Form.Group>

            <Form.Group as={Col} md={2} className="mb-3" controlId="requestForm.longitude">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.longitude')}</Form.Label>
              <Form.Control
                disabled={!props.allowed.edit_site_visit}
                name="longitude"
                type="text"
                value={longitude}
                onChange={(e) => setLongitude(e.target.value)} />
            </Form.Group>

            <Form.Group as={Col} md={2} className="mb-3" controlid="requestForm.start_date">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.start_date')}</Form.Label><br/>
              <SingleDatePicker
                disabled={!props.allowed.edit_site_visit}
                date={startDate}// momentPropTypes.momentObj or null
                onDateChange={start_date => setStartDate( start_date )} // PropTypes.func.isRequired
                focused={startFocused} // PropTypes.bool
                onFocusChange={({ focused: start_focused }) => setStartFocused( start_focused )} // PropTypes.func.isRequired
                id="start_date" // PropTypes.string.isRequired,
                isOutsideRange={()=>false}
              />
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col} md={4} className="mb-3" controlid="requestForm.partner">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.partner')}</Form.Label>
              <Form.Control
                disabled={!props.allowed.edit_site_visit}
                name="partner"
                type="text"
                value={partner}
                onChange={(e) => setPartner(e.target.value)} />
            </Form.Group>

            <Form.Group as={Col} md={4} controlId="requestForm.request_status">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.request_status')}</Form.Label>
              <SelectSingle
                isDisabled={!props.allowed.edit_site_visit_meta}
                selected={requestStatus}
                onValueChange={(e) => setRequestStatus(e.value)}
                url={appConstants.EVENT_STATUSES_URL}
                isClearable={false}
                placeholder={I18n.t('selects.single')} />
            </Form.Group>

            <Form.Group as={Col} md={4} className="mb-3" controlid="requestForm.monitoring_cycle">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.monitoring_cycle')}</Form.Label>
              <SelectSingle
                isDisabled={!props.allowed.edit_site_visit_meta}
                selected={monitoringCycleId}
                onValueChange={(e) => setMonitoringCycleId(e ? e.value : "")}
                url={appConstants.MONITORING_CYCLES_URL}
                isClearable={true}
                placeholder={I18n.t('selects.single')} />
            </Form.Group>

            <Form.Group as={Col} md={4} className="mb-3" controlid="requestForm.data_collection_partner">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.office')}</Form.Label>
              <SelectSingle
                isDisabled={!props.allowed.edit_site_visit}
                selected={officeId}
                onValueChange={(e) => setOfficeId(e ? e.value : "")}
                url={appConstants.OFFICES_URL}
                isClearable={true}
                placeholder={I18n.t('selects.single')} />
            </Form.Group>

            <Form.Group as={Col} md={4} className="mb-3" controlId="requestForm.visitType">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.visit_type')}</Form.Label>
              <SelectSingle
                isDisabled={!props.allowed.edit_site_visit_meta}
                selected={visitType}
                onValueChange={(e) => setVisitType(e ? e.value : "")}
                url={appConstants.VISIT_TYPES_URL}
                isClearable={true}
                placeholder={I18n.t('selects.single')} />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} md={4} className="mb-3" controlid="requestForm.quarter">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.quarter')}</Form.Label>
              <SelectSingle
                isDisabled={!props.allowed.edit_site_visit_meta}
                selected={quarterId}
                onValueChange={(e) => setQuarterId(e ? e.value : "")}
                url={appConstants.QUARTERS_URL + '.json'}
                isClearable={true}
                placeholder={I18n.t('selects.single')} />
            </Form.Group>

            <Form.Group as={Col} md={4} className="mb-3" controlid="requestForm.monitoringMonth">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.monitoring_month')}</Form.Label>
              <SelectSingle
                isDisabled={!props.allowed.edit_site_visit_meta}
                selected={monitoringMonthId}
                onValueChange={(e) => setMonitoringMonthId(e ? e.value : "")}
                url={appConstants.MONITORING_MONTHS_URL + '.json'}
                isClearable={true}
                placeholder={I18n.t('selects.single')} />
            </Form.Group>

            { isWfp ?
              <Form.Group as={Col} md={4} className="mb-3" controlid="requestForm.wfp_description">
                <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.wfp_description')}</Form.Label>
                <Form.Control
                  disabled={!props.allowed.edit_site_visit}
                  name="wfpDescription"
                  as="textarea"
                  rows="3"
                  value={wfpDescription}
                  onChange={(e) => setWfpDescription(e.target.value)} />
              </Form.Group>
            : "" }
          </Row>
          { requestStatus == 'pending' ?
          (
          <Row>
            <Form.Group as={Col} md={6} className="mb-3" controlid="requestForm.postponed_reason">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.request_justification')}</Form.Label>
              <SelectSingle
                isDisabled={!props.allowed.edit_site_visit}
                selected={requestJustification}
                onValueChange={(e) => setRequestJustification(e ? e.value : "")}
                url={appConstants.JUSTIFICATIONS_URL}
                isClearable={true}
                placeholder={I18n.t('selects.single')} />
            </Form.Group>

            <Form.Group as={Col} md={6} className="mb-3" controlid="requestForm.postponed_details">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.request_justification_details')}</Form.Label>
              <Form.Control
                disabled={!props.allowed.edit_site_visit}
                name="requestJustificationDetails"
                type="text"
                value={requestJustificationDetails}
                onChange={(e) => setRequestJustificationDetails(e.target.value)} />
            </Form.Group>
          </Row> ) : ""
          }

          <Form.Group  className="mb-3" controlid="requestForm.buttons">
            <Button disabled={!props.allowed.edit_site_visit || saveInProgress} variant="outline-primary" onClick={() => handleSubmit()}>{id ? I18n.t("navigation.update") : I18n.t("navigation.add")}</Button> &nbsp;
            {renderUpdatePodioButton()} &nbsp;
            {renderDuplicateButton()} &nbsp;
            {id ? renderDeleteButton() : renderCancelButton()}
          </Form.Group>
        </Tab>
        <Tab eventKey="ipOutputs" title={I18n.t('navigation.ip_outputs_tab')} className="mb-3">
          <Row className="mt-3">
            <Form.Group as={Col} md={4} className="mb-3" controlid="requestForm.sector_activities_funded">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.sector_activities_funded')}&nbsp;{renderInfoIcon('sector_activities_funded')}</Form.Label>
              <Form.Check
                disabled={!props.allowed.edit_ip_outputs}
                type="switch"
                name="sectorActivitiesFunded"
                checked={sectorActivitiesFunded}
                value={true}
                onChange={() => setSectorActivitiesFunded(!sectorActivitiesFunded)}
                bsPrefix="sectorActivitiesFunded-switch" />
            </Form.Group>

            <Form.Group as={Col} md={4} className="mb-3" controlid="requestForm.supported_facilities">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.supported_facilities')}<span className="text-danger">*</span>&nbsp;{renderInfoIcon('supported_facilities')}</Form.Label>
                <Form.Control
                  disabled={!props.allowed.edit_ip_outputs}
                  name="supportedFacilities"
                  as="textarea"
                  rows="3"
                  value={supportedFacilities}
                  onChange={(e) => setSupportedFacilities(e.target.value)} />
            </Form.Group>

            <Form.Group as={Col} md={4} className="mb-3" controlid="requestForm.type_of_support">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.type_of_support')}&nbsp;{renderInfoIcon('type_of_support')}</Form.Label>
                <Form.Control
                  disabled={!props.allowed.edit_ip_outputs}
                  name="typeOfSupport"
                  as="textarea"
                  rows="3"
                  value={typeOfSupport}
                  onChange={(e) => setTypeOfSupport(e.target.value)} />
            </Form.Group>

            <Form.Group as={Col} md={4} className="mb-3" controlid="requestForm.cash_for_work">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.cash_for_work')}&nbsp;{renderInfoIcon('cash_for_work')}</Form.Label>
                <Form.Control
                  disabled={!props.allowed.edit_ip_outputs}
                  name="cashForWork"
                  as="textarea"
                  rows="3"
                  value={cashForWork}
                  onChange={(e) => setCashForWork(e.target.value)} />
            </Form.Group>

            <Form.Group as={Col} md={4} className="mb-3" controlid="requestForm.staff_being_trained">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.staff_being_trained')}<span className="text-danger">*</span>&nbsp;{renderInfoIcon('staff_being_trained')}</Form.Label>
                <Form.Control
                  disabled={!props.allowed.edit_ip_outputs}
                  name="staffBeingTrained"
                  as="textarea"
                  rows="3"
                  value={staffBeingTrained}
                  onChange={(e) => setStaffBeingTrained(e.target.value)} />
            </Form.Group>

            <Form.Group as={Col} md={4} className="mb-3" controlid="requestForm.types_of_distributions">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.types_of_distributions')}&nbsp;{renderInfoIcon('types_of_distributions')}</Form.Label>
                <Form.Control
                  disabled={!props.allowed.edit_ip_outputs}
                  name="typesOfDistributions"
                  as="textarea"
                  rows="3"
                  value={typesOfDistributions}
                  onChange={(e) => setTypesOfDistributions(e.target.value)} />
            </Form.Group>

            <Form.Group as={Col} md={4} className="mb-3" controlid="requestForm.number_of_distributions_per_beneficiary">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.number_of_distributions_per_beneficiary')}<span className="text-danger">*</span>&nbsp;{renderInfoIcon('number_of_distributions_per_beneficiary')}</Form.Label>
                <Form.Control
                  disabled={!props.allowed.edit_ip_outputs}
                  name="numberOfDistributionsPerBeneficiary"
                  as="textarea"
                  rows="3"
                  value={numberOfDistributionsPerBeneficiary}
                  onChange={(e) => setNumberOfDistributionsPerBeneficiary(e.target.value)} />
            </Form.Group>

            <Form.Group as={Col} md={4} className="mb-3" controlid="requestForm.value_per_distribution">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.value_per_distribution')}&nbsp;{renderInfoIcon('value_per_distribution')}</Form.Label>
                <Form.Control
                  disabled={!props.allowed.edit_ip_outputs}
                  name="valuePerDistribution"
                  as="textarea"
                  rows="3"
                  value={valuePerDistribution}
                  onChange={(e) => setValuePerDistribution(e.target.value)} />
            </Form.Group>

            <Form.Group as={Col} md={4} className="mb-3" controlid="requestForm.number_of_targeted_beneficiaries_dist">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.number_of_targeted_beneficiaries_dist')}<span className="text-danger">*</span>&nbsp;{renderInfoIcon('number_of_targeted_beneficiaries_dist')}</Form.Label>
                <Form.Control
                  disabled={!props.allowed.edit_ip_outputs}
                  name="numberOfTargetedBeneficiariesDist"
                  as="textarea"
                  rows="3"
                  value={numberOfTargetedBeneficiariesDist}
                  onChange={(e) => setNumberOfTargetedBeneficiariesDist(e.target.value)} />
            </Form.Group>

            <Form.Group as={Col} md={4} className="mb-3" controlid="requestForm.services_offered_to_ben">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.services_offered_to_ben')}&nbsp;{renderInfoIcon('services_offered_to_ben')}</Form.Label>
                <Form.Control
                  disabled={!props.allowed.edit_ip_outputs}
                  name="servicesOfferedToBen"
                  as="textarea"
                  rows="3"
                  value={servicesOfferedToBen}
                  onChange={(e) => setServicesOfferedToBen(e.target.value)} />
            </Form.Group>

            <Form.Group as={Col} md={4} className="mb-3" controlid="requestForm.number_of_beneficiaries_targeted_serv">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.number_of_beneficiaries_targeted_serv')}<span className="text-danger">*</span>&nbsp;{renderInfoIcon('number_of_beneficiaries_targeted_serv')}</Form.Label>
                <Form.Control
                  disabled={!props.allowed.edit_ip_outputs}
                  name="numberOfBeneficiariesTargetedServ"
                  as="textarea"
                  rows="3"
                  value={numberOfBeneficiariesTargetedServ}
                  onChange={(e) => setNumberOfBeneficiariesTargetedServ(e.target.value)} />
            </Form.Group>

            <Form.Group as={Col} md={4} className="mb-3" controlid="requestForm.type_of_formal_training">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.type_of_formal_training')}<span className="text-danger">*</span>&nbsp;{renderInfoIcon('type_of_formal_training')}</Form.Label>
                <Form.Control
                  disabled={!props.allowed.edit_ip_outputs}
                  name="typeOfFormalTraining"
                  as="textarea"
                  rows="3"
                  value={typeOfFormalTraining}
                  onChange={(e) => setTypeOfFormalTraining(e.target.value)} />
            </Form.Group>

            <Form.Group as={Col} md={4} className="mb-3" controlid="requestForm.other_activities">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.other_activities')}&nbsp;{renderInfoIcon('other_activities')}</Form.Label>
                <Form.Control
                  disabled={!props.allowed.edit_ip_outputs}
                  name="otherActivities"
                  as="textarea"
                  rows="3"
                  value={otherActivities}
                  onChange={(e) => setOtherActivities(e.target.value)} />
            </Form.Group>

            <Form.Group as={Col} md={4} className="mb-3" controlid="requestForm.coordinator_details">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.coordinator_details')}&nbsp;{renderInfoIcon('coordinator_details')}</Form.Label>
                <Form.Control
                  disabled={!props.allowed.edit_ip_outputs}
                  name="coordinatorDetails"
                  as="textarea"
                  rows="3"
                  value={coordinatorDetails}
                  onChange={(e) => setCoordinatorDetails(e.target.value)} />
            </Form.Group>

            <Form.Group as={Col} md={4} className="mb-3" controlid="requestForm.alternate_coordinator">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.alternate_coordinator')}&nbsp;{renderInfoIcon('alternate_coordinator')}</Form.Label>
                <Form.Control
                  disabled={!props.allowed.edit_ip_outputs}
                  name="alternateCoordinator"
                  as="textarea"
                  rows="3"
                  value={alternateCoordinator}
                  onChange={(e) => setAlternateCoordinator(e.target.value)} />
            </Form.Group>

            <Form.Group as={Col} md={4} className="mb-3" controlid="requestForm.anticipated_dates">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.anticipated_dates')}<span className="text-danger">*</span>&nbsp;{renderInfoIcon('anticipated_dates')}</Form.Label>
                <Form.Control
                  disabled={!props.allowed.edit_ip_outputs}
                  name="anticipatedDates"
                  as="textarea"
                  rows="3"
                  value={anticipatedDates}
                  onChange={(e) => setAnticipatedDates(e.target.value)} />
            </Form.Group>

            <Form.Group className="mb-3" controlid="requestForm.buttons">
              <Button disabled={!props.allowed.submit_site_visit || saveInProgress} variant="outline-primary" onClick={() => handleSubmit()}>{id ? I18n.t("navigation.update") : I18n.t("navigation.add")}</Button> &nbsp;
              <ExportIpOutputs id={id} headers={props.ipOutputsExportHeaders} updated={updated} allowed={props.allowed.export_ip_outputs} />
            </Form.Group>
          </Row>
        </Tab>
        <Tab eventKey="ipInfo" title={I18n.t('navigation.ip_information_request')} className="mb-3">
          <Row className="mt-3">
            <Form.Group as={Col} md={ipSiteActive ? 3 : 1} className="mb-3" controlid="requestForm.ip_site_active">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.ip_site_active')}<span className="text-danger">*</span></Form.Label>
              <Form.Check
                disabled={!props.allowed.edit_site_visit}
                type="switch"
                name="ipSiteActive"
                checked={ipSiteActive}
                value={true}
                onChange={() => setIpSiteActive(!ipSiteActive)}
                bsPrefix="ipSiteActive-switch" />
            </Form.Group>
            {!ipSiteActive ?
            <Form.Group as={Col} md={2} className="mb-3" controlid="requestForm.ip_site_inactive_reason">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.ip_site_inactive_reason')}<span className="text-danger">*</span></Form.Label>
                <Form.Control
                  disabled={!props.allowed.edit_site_visit || ipSiteActive}
                  name="ipSiteInactiveReason"
                  type="text"
                  value={ipSiteInactiveReason}
                  onChange={(e) => setIpSiteInactiveReason(e.target.value)} />
            </Form.Group>
            : ""}
            {hasFacilitySector ?
            <Form.Group as={Col} md={2} className="mb-3" controlid="requestForm.facility_type">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.facility_type')}<span className="text-danger">*</span></Form.Label>
              <SelectSingle
                isDisabled={!props.allowed.edit_site_visit}
                selected={facilityType}
                onValueChange={(e) => setFacilityType(e ? e.value : "")}
                url={appConstants.FACILITY_TYPES_URL}
                isClearable={false}
                placeholder={I18n.t('selects.single')} />
            </Form.Group>
            : ""}
            <Form.Group as={Col} md={2} className="mb-3" controlid="requestForm.ip_liason_name">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.ip_liason_name')}<span className="text-danger">*</span></Form.Label>
                <Form.Control 
                  disabled={!props.allowed.edit_site_visit}
                  name="ipLiasonName"
                  type="text"
                  value={ipLiasonName}
                  onChange={(e) => setIpLiasonName(e.target.value)} />
            </Form.Group>
            <Form.Group as={Col} md={3} className="mb-3" controlid="requestForm.ip_liason_email">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.ip_liason_email')}<span className="text-danger">*</span></Form.Label>
                <Form.Control
                  disabled={!props.allowed.edit_site_visit}
                  name="ipLiasonEmail"
                  type="text"
                  value={ipLiasonContact}
                  onChange={(e) => setIpLiasonContact(e.target.value)} />
            </Form.Group>
            <Form.Group as={Col} md={2} className="mb-3" controlid="requestForm.ip_liason_phone">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.ip_liason_phone')}<span className="text-danger">*</span></Form.Label>
                <Form.Control
                  disabled={!props.allowed.edit_site_visit}
                  name="ipLiasonPhone"
                  type="text"
                  value={ipLiasonPhone}
                  onChange={(e) => setIpLiasonPhone(e.target.value)} />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} md={3} className="mb-3" controlid="requestForm.community_leader_name_1">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.community_leader_name_1')}<span className="text-danger">*</span></Form.Label>
                <Form.Control 
                  disabled={!props.allowed.edit_site_visit}
                  name="communityLeaderNameOne"
                  type="text"
                  value={communityLeaderNameOne}
                  onChange={(e) => setCommunityLeaderNameOne(e.target.value)} />
            </Form.Group>
            <Form.Group as={Col} md={3} className="mb-3" controlid="requestForm.community_leader_contact_1">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.community_leader_contact_1')}<span className="text-danger">*</span></Form.Label>
                <Form.Control
                  disabled={!props.allowed.edit_site_visit}
                  name="communityLeaderContactOne"
                  type="text"
                  value={communityLeaderContactOne}
                  onChange={(e) => setCommunityLeaderContactOne(e.target.value)} />
            </Form.Group>
            <Form.Group as={Col} md={3} className="mb-3" controlid="requestForm.community_leader_name_2">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.community_leader_name_2')}</Form.Label>
                <Form.Control
                  disabled={!props.allowed.edit_site_visit}
                  name="communityLeaderNameTwo"
                  type="text"
                  value={communityLeaderNameTwo}
                  onChange={(e) => setCommunityLeaderNameTwo(e.target.value)} />
            </Form.Group>
            <Form.Group as={Col} md={3} className="mb-3" controlid="requestForm.community_leader_contact_2">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.community_leader_contact_2')}</Form.Label>
                <Form.Control
                  disabled={!props.allowed.edit_site_visit}
                  name="communityLeaderContactTwo"
                  type="text"
                  value={communityLeaderContactTwo}
                  onChange={(e) => setCommunityLeaderContactTwo(e.target.value)} />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} md={3} className="mb-3" controlid="requestForm.community_volunteer_name_1">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.community_volunteer_name_1')}</Form.Label>
                <Form.Control
                  disabled={!props.allowed.edit_site_visit}
                  name="communityVolunteerNameOne"
                  type="text"
                  value={communityVolunteerNameOne}
                  onChange={(e) => setCommunityVolunteerNameOne(e.target.value)} />
            </Form.Group>
            <Form.Group as={Col} md={3} className="mb-3" controlid="requestForm.community_volunteer_contact_1">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.community_volunteer_contact_1')}</Form.Label>
                <Form.Control
                  disabled={!props.allowed.edit_site_visit}
                  name="communityVolunteerContactOne" 
                  type="text"
                  value={communityVolunteerContactOne}
                  onChange={(e) => setCommunityVolunteerContactOne(e.target.value)} />
            </Form.Group>
            <Form.Group as={Col} md={3} className="mb-3" controlid="requestForm.community_volunteer_name_2">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.community_volunteer_name_2')}</Form.Label>
                <Form.Control
                  disabled={!props.allowed.edit_site_visit}
                  name="communityVolunteerNameTwo"
                  type="text"
                  value={communityVolunteerNameTwo}
                  onChange={(e) => setCommunityVolunteerNameTwo(e.target.value)} />
            </Form.Group>
            <Form.Group as={Col} md={3} className="mb-3" controlid="requestForm.community_volunteer_contact_2">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.community_volunteer_contact_2')}</Form.Label>
                <Form.Control
                  disabled={!props.allowed.edit_site_visit}
                  name="communityVolunteerContactTwo"
                  type="text"
                  value={communityVolunteerContactTwo}
                  onChange={(e) => setCommunityVolunteerContactTwo(e.target.value)} />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} md={3} className="mb-3" controlid="requestForm.ip_sub_recipient">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.ip_sub_recipient')}</Form.Label>
                <Form.Control
                  disabled={!props.allowed.edit_ip_outputs}
                  name="ipSubRecipient"
                  as="textarea"
                  rows="3"
                  value={ipSubRecipient}
                  onChange={(e) => setIpSubRecipient(e.target.value)} />
            </Form.Group>
            <Form.Group as={Col} md={7} className="mb-3" controlid="requestForm.ip_tab_ip_comments">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.ip_tab_ip_comments')}</Form.Label>
                <Form.Control
                  disabled={!props.allowed.edit_site_visit}
                  name="ipTabIpComments"
                  as="textarea"
                  rows="3"
                  value={ipTabIpComments}
                  onChange={(e) => setIpTabIpComments(e.target.value)} />
            </Form.Group>
            <Form.Group as={Col} md={2} className="mb-3 mt-2" controlid="requestForm.report_upload_button">
              <Button variant="outline-secondary" size="md" className="mt-4 ms-3" onClick={()=>showSpecificResourceModal('sv_ip_doc')}>{I18n.t('activerecord.attributes.monitoring_request.upload_supporting_document')}</Button>
            </Form.Group>
          </Row>
          <Form.Group  className="mb-3" controlid="requestForm.buttons">
            <Button disabled={!props.allowed.edit_site_visit || saveInProgress} variant="outline-primary" onClick={() => handleSubmit()}>{id ? I18n.t("navigation.update") : I18n.t("navigation.add")}</Button> &nbsp;
          </Form.Group>
        </Tab>
        { props.allowed.smlp2_review_tab ?
          (
          <Tab eventKey="smlp2_review" title={I18n.t('navigation.smlp2_review_tab')} className="mb-3">
            <Row className="mt-3">
              <Form.Group as={Col} md={2} controlid="requestForm.smlp2_data_reviewed" className="mb-3" >
                <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.smlp2_data_reviewed')}</Form.Label>
                <Form.Check
                  disabled={!props.allowed.edit_site_visit}
                  type="switch"
                  name="smlp2DataReviewed"
                  checked={smlp2DataReviewed}
                  value={true}
                  onChange={() => setSmlp2DataReviewed(!smlp2DataReviewed)}
                  bsPrefix="smlp2DataReviewed-switch" />
              </Form.Group>
              <Form.Group as={Col} md={2} controlid="requestForm.smlp2_issue_identified" className="mb-3" >
                <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.smlp2_issue_identified')}</Form.Label>
                <Form.Check
                  disabled={!props.allowed.edit_site_visit}
                  type="switch"
                  name="issueIdentifiedSmlpl2"
                  checked={smlp2IssueIdentified}
                  value={true}
                  onChange={() => setSmlp2IssueIdentified(!smlp2IssueIdentified)}
                  bsPrefix="smlp2IssueIdentified-switch" />
              </Form.Group>
              <Form.Group as={Col} md={4} controlid="requestForm.smlp2_monitoring_issue" className="mb-3" >
                <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.smlp2_monitoring_issue')}</Form.Label>
                <SelectSingle
                  isDisabled={!props.allowed.edit_site_visit}
                  selected={smlp2MonitoringIssue}
                  onValueChange={(e) => setSmlp2MonitoringIssue(e ? e.value : "")}
                  url={appConstants.MONITORING_ISSUES_URL}
                  placeholder={I18n.t('selects.single')}
                  isClearable={true} />
              </Form.Group>
              <Form.Group as={Col} md={2} controlid="requestForm.smlp2_issue_fixed" className="mb-3" >
                <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.smlp2_issue_fixed')}</Form.Label>
                <Form.Check
                  disabled={!props.allowed.edit_site_visit}
                  type="switch"
                  name="smlp2IssueFixed"
                  checked={smlp2IssueFixed}
                  value={true}
                  onChange={() => setSmlp2IssueFixed(!smlp2IssueFixed)}
                  bsPrefix="smlp2IssueFixed-switch" />
              </Form.Group>
              <Form.Group as={Col} md={2} controlid="requestForm.smlp2_date_fixed" className="mb-3" >
                <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.smlp2_date_fixed')}</Form.Label><br/>
                <SingleDatePicker
                  disabled={!props.allowed.edit_site_visit}
                  date={smlp2DateFixed}// momentPropTypes.momentObj or null
                  onDateChange={smlp2_date_fixed => setSmlp2DateFixed( smlp2_date_fixed )} // PropTypes.func.isRequired
                  focused={smlp2FixedFocused} // PropTypes.bool
                  onFocusChange={({ focused: smlp2_fixed_focused }) => setSmlp2FixedFocused( smlp2_fixed_focused )} // PropTypes.func.isRequired
                  id="smpl2_date_fixed" // PropTypes.string.isRequired,
                  isOutsideRange={()=>false}
                />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} md={6} controlid="requestForm.smlp2_other_issues" className="mb-3" >
                <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.smlp2_other_issues')}</Form.Label>
                <Form.Control
                  disabled={!props.allowed.edit_site_visit}
                  name="smlp2OtherIssues"
                  as="textarea"
                  rows={3}
                  value={smlp2OtherIssues}
                  onChange={(e) => setSmlp2OtherIssues(e.target.value)} />
              </Form.Group>
              <Form.Group as={Col} md={6} controlid="requestForm.smlp2_action_point" className="mb-3" >
                <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.smlp2_action_point')}</Form.Label>
                <Form.Control
                  disabled={!props.allowed.edit_site_visit}
                  name="smlp2ActionPoint"
                  as="textarea"
                  rows={3}
                  value={smlp2ActionPoint}
                  onChange={(e) => setSmlp2ActionPoint(e.target.value)} />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} md={12} controlid="requestForm.smlp2_review_comments" className="mb-3" >
                <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.smlp2_review_comments')}</Form.Label>
                <Form.Control
                  disabled={!props.allowed.edit_site_visit}
                  name="smlp2ReviewComments"
                  as="textarea"
                  rows={3}
                  value={smlp2ReviewComments}
                  onChange={(e) => setSmlp2ReviewComments(e.target.value)} />
              </Form.Group>
            </Row>

            <Form.Group className="mb-3" controlid="requestForm.buttons">
              <Button disabled={!props.allowed.edit_site_visit || saveInProgress} variant="outline-primary" onClick={() => handleSubmit()}>{id ? I18n.t("navigation.update") : I18n.t("navigation.add")}</Button> &nbsp;
            </Form.Group>
          </Tab>
          ) : ""
        }
        <Tab eventKey="report" title={I18n.t('navigation.report_tab')} className="mb-3">
        { props.allowed.report_tab ?
          (
            <Row className="mt-3">
              <Form.Group as={Col} md={2} className="mb-3 pe-5" controlid="requestForm.report_due">
                <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.report_due')}</Form.Label><br/>
                  <SingleDatePicker
                    disabled={!props.allowed.edit_site_visit}
                    date={reportDue}// momentPropTypes.momentObj or null
                    onDateChange={report_due => setReportDue( report_due )} // PropTypes.func.isRequired
                    focused={reportDueFocused} // PropTypes.bool
                    onFocusChange={({ focused: report_due_focused }) => setReportDueFocused( report_due_focused )} // PropTypes.func.isRequired
                    id="report_due_focused" // PropTypes.string.isRequired,
                    isOutsideRange={()=>false}
                  />
              </Form.Group>

              <Form.Group as={Col} md={2} className="mb-3 px-5" controlid="requestForm.sent_to_ip">
                <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.sent_to_ip')}</Form.Label><br/>
                <SingleDatePicker
                  disabled={!props.allowed.edit_site_visit}
                  date={sentToIp}// momentPropTypes.momentObj or null
                  onDateChange={sent_to_ip => setSentToIp( sent_to_ip )} // PropTypes.func.isRequired
                  focused={sentToIpFocused} // PropTypes.bool
                  onFocusChange={({ focused: sent_to_ip_focused }) => setSentToIpFocused( sent_to_ip_focused )} // PropTypes.func.isRequired
                  id="sent_to_ip" // PropTypes.string.isRequired,
                  isOutsideRange={()=>false}
                />
              </Form.Group>

              <Form.Group as={Col} md={2} className="mb-3 px-5" controlid="requestForm.ip_feedback_due">
                <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.ip_feedback_due')}</Form.Label><br/>
                  <SingleDatePicker
                    disabled={!props.allowed.edit_site_visit}
                    date={ipFeedbackDue}// momentPropTypes.momentObj or null
                    onDateChange={ip_feedback_due => setIpFeedbackDue( ip_feedback_due )} // PropTypes.func.isRequired
                    focused={ipFeedbackDueFocused} // PropTypes.bool
                    onFocusChange={({ focused: ip_feedback_due_focused }) => setIpFeedbackDueFocused( ip_feedback_due_focused )} // PropTypes.func.isRequired
                    id="ip_feedback_due_focused" // PropTypes.string.isRequired,
                    isOutsideRange={()=>false}
                    />
              </Form.Group>

              <Form.Group as={Col} md={2} className="mb-3" controlid="requestForm.ip_feedback_received">
                <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.ip_feedback_received')}</Form.Label>
                  <Form.Check
                    disabled={!props.allowed.edit_site_visit}
                    type="switch"
                    name="ipFeedbackReceived"
                    checked={ipFeedbackReceived}
                    value={true}
                    onChange={() => setIpFeedbackReceived(!ipFeedbackReceived)}
                    bsPrefix="ipFeedbackReceived-switch" />
              </Form.Group>

              <Form.Group as={Col} md={4} className="mb-3" controlid="requestForm.report_upload_button">
                <Button variant="outline-secondary" size="md" className="mt-4 ms-3" onClick={()=>showSpecificResourceModal('svr_support_doc')}>{I18n.t('activerecord.attributes.monitoring_request.upload_supporting_document')}</Button>
              </Form.Group>
            </Row>
          ) : ""
        } 

          <Row className="mt-3">
            <Col md={6}>
              <Form.Group as={Col} md={12} className="mb-3" controlid="requestForm.ip_comments">
                <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.ip_comments')} &nbsp; {ipCommentsUpdated ? <span className="fst-italic fw-lighter">{I18n.t('activerecord.attributes.monitoring_request.comments_updated')}: {moment(ipCommentsUpdated).tz(localTimezone()).format('MMMM Do YYYY, h:mm:ss a')} by {ipCommentsUpdater}</span> : ""}</Form.Label><br/>
                <Form.Control
                  disabled={!props.allowed.ip_comments}
                  as="textarea"
                  name="ip_comments"
                  value={ipComments}
                  onChange={(e) => setIpComments(e.target.value)}
                  rows="4" />
              </Form.Group>
            </Col>
            { props.id && ipCommentsExist ?
              (
                <Form.Group as={Col} md={6} className="mb-3 comments-confined" controlId="requestForm.comments">
                  <Form.Label>{I18n.t('navigation.discussion')} </Form.Label>
                  { renderCloseDiscussionSwitch() }
                  <Comments
                    objectId={props.id}
                    objectType="monitoring_request"
                    commentType="sv_report"
                    open={!discussionClosed}
                    sort={"asc"}
                    authenticityToken={props.authenticityToken}
                    className="mb-3" />
                </Form.Group>
              ) : ""
            }
          </Row>

          <h6>{I18n.t('activerecord.attributes.monitoring_request.flash_report')}</h6>
          <hr />
          <Row>
            <Col md={6}>
              <Row>
                <Form.Group as={Col} md={1} className="mb-3 mt-2">
                  { renderFlashReportUploaded() }
                </Form.Group>

                <Form.Group as={Col} md={3} className="mb-3 mt-2" controlid="requestForm.flash_report_upload_button">
                  <Button variant="outline-secondary" size="md" className="mt-4 ms-3" onClick={()=>showSpecificResourceModal('flash_report')}>{I18n.t('activerecord.attributes.monitoring_request.upload_flash_report')}</Button>
                </Form.Group>

                <Form.Group as={Col} md={4} controlid="requestForm.flash_report_ready" className="mb-3" >
                  <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.flash_report_ready')}</Form.Label>
                  <Form.Check
                    disabled={!props.allowed.edit_site_visit}
                    type="switch"
                    name="flashReportReady"
                    checked={flashReportReady}
                    value={true}
                    onChange={() => (setFlashReportReady(!flashReportReady), !flashReportStatus ? setFlashReportStatus('fr_created') : "")}
                    bsPrefix="flashReportReady-switch" />
                </Form.Group>

                <Form.Group as={Col} md={4} className="mb-3" controlId="requestForm.flash_report_status">
                  <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.flash_report_status')}</Form.Label>
                  <SelectSingle
                    isDisabled={!props.allowed.edit_site_visit_meta}
                    selected={flashReportStatus}
                    onValueChange={(e) => setFlashReportStatus(e ? e.value : "")}
                    url={appConstants.FLASH_REPORT_STATUSES_URL}
                    isClearable={true}
                    placeholder={I18n.t('selects.single')} />
                </Form.Group>
              </Row>

              <Form.Group as={Col} md={12} className="my-3" controlid="requestForm.ip_flash_comments">
                <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.ip_flash_comments')}  &nbsp; {ipFlashCommentsUpdated ? <span className="fst-italic fw-lighter">{I18n.t('activerecord.attributes.monitoring_request.comments_updated')}: {moment(ipFlashCommentsUpdated).tz(localTimezone()).format('MMMM Do YYYY, h:mm:ss a')} by {ipFlashCommentsUpdater}</span> : ""}</Form.Label><br/>
                <Form.Control
                  disabled={true}
                  as="textarea"
                  name="ip_flash_comments"
                  value={ipFlashComments}
                  onChange={(e) => setIpFlashComments(e.target.value)}
                  rows="4" />
              </Form.Group>
            </Col>

            { props.id && ipFlashComments.length > 0 ?
              (
                <Form.Group as={Col} md={6} className="mb-3 comments-confined" controlId="requestForm.flash_comments">
                  <Form.Label>{I18n.t('navigation.flash_discussion')} </Form.Label>
                  <Comments
                    objectId={props.id}
                    objectType="monitoring_request"
                    commentType="flash_report"
                    open={false}
                    sort={"asc"}
                    authenticityToken={props.authenticityToken}
                    className="mb-3" />
                </Form.Group>
              ) : ""
            }
          </Row>

          <Form.Group className="mb-3" controlid="requestForm.buttons">
            <Button disabled={!props.allowed.submit_site_visit || saveInProgress} variant="outline-primary" onClick={() => handleSubmit()}>{id ? I18n.t("navigation.update") : I18n.t("navigation.add")}</Button> &nbsp;
          </Form.Group>
        </Tab>

        { renderFulcrumTab() }
        { resourcesCount ?
          (
            <Tab eventKey="resources" title={I18n.t('navigation.resources_tab')} className="mb-3">
              <div className="mt-3">
                <VisitResourceTable siteVisitId={props.id} refresh={showAddResourceModal} canDownload={props.allowed.download_report} />
              </div>
            </Tab>
          ) : ""
        }
      </Tabs>
    </Form>
  )
}