import React, { useState } from 'react'
import axios from 'axios'
import I18n from 'i18n-js/locales.js'
import { Form, Row, Col, Button, Alert, Spinner } from 'react-bootstrap'
import Select from 'react-select'
import { appConstants, monitoringCycleFullName } from 'utils/constants.js'


export default function SurveyDataExportForm(props) {
  const [formIds, setFormIds] = useState([])
  const [formTypeId, setFormTypeId] = useState('')
  const [siteVisitIds, setSiteVisitIds] = useState([])
  const [monitoringCycleIds, setMonitoringCycleIds] = useState([])
  const [inProgress, setInProgress] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')

  const createFormData = function()  {
    let formData = new FormData()
    formIds.forEach(id => { formData.append('fulcrum_form[form_ids][]', id) })
    formData.append('fulcrum_form[form_type_id]', formTypeId)
    siteVisitIds.forEach(s => { formData.append('fulcrum_form[monitoring_request_ids][]', s) })
    monitoringCycleIds.forEach(m => { formData.append('fulcrum_form[monitoring_cycle_ids][]', m) })

    return formData
  }

  const handleSubmit = async() => {
    setInProgress(true)
    axios.defaults.headers.common["X-CSRF-TOKEN"] = props.authenticityToken;
    const data = createFormData()

    try {
      const downloadFile = (url, filename) => {
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', filename)
        document.body.appendChild(link)
        link.click()
        link.remove()
      }

      const res = await axios.get(`${appConstants.SURVEY_DATA_EXPORT_URL}?${new URLSearchParams(data).toString()}`, {
        responseType: 'blob'
      })

      const blobUrl = window.URL.createObjectURL(new Blob([res.data]))
      const filename = res.headers['content-disposition'].split('filename=')[1]
      downloadFile(blobUrl, filename)
      setAlertMessage(I18n.t("notices.export_success"))
    } catch (error) {
      setAlertMessage(I18n.t("notices.export_failure"))
    } finally {
      setInProgress(false)
    }
  }

  const renderAlertMessage = () => {
    return alertMessage ? (
      <Alert variant={'info'} onClose={() => setAlertMessage("")} dismissible>
        {alertMessage}
      </Alert>
    ) : ""
  }

  return  (
    <Form>
      <Row className="mb-3">
        <Col>
          {renderAlertMessage()}
        </Col>
      </Row>
      <p>{I18n.t('headers.survey_data_export.instructions')}</p>
      <Row className="mb-3">
        <Col md={6}>
            <Form.Group controlId="form_id" className="mb-3">
              <Select
                isMulti={true}
                isClearable={true}
                isDisabled={formTypeId || siteVisitIds.length > 0}
                onChange={(e) => setFormIds(e ? e.map(i => i.value) : [])}
                value={formIds ? formIds.map(id => ({value: id, label: props.forms.find(i => i.id === id).name})) : null}
                options={props.forms.map(i => ({value: i.id, label: i.name}))}
                placeholder={I18n.t('headers.survey_data_export.select_form')}
                styles={{
                  menu: provided => ({ ...provided, zIndex: 9999 })
                }} />
            </Form.Group>
            <Form.Group controlId="form_type_id" className="mb-3">
              <Select
                clearValue={true}
                isClearable={true}
                onChange={(e) => setFormTypeId(e ? e.value : '')}
                value={formTypeId ? {value: formTypeId, label: props.formTypes.find(i => i.id === formTypeId).name} : null}
                options={props.formTypes.map(i => ({value: i.id, label: i.name}))}
                placeholder={I18n.t('headers.survey_data_export.select_form_type')}
                isDisabled={formIds.length > 0 || siteVisitIds.length > 0}
                styles={{
                  menu: provided => ({ ...provided, zIndex: 9999 })
                }} />
            </Form.Group>
            <Form.Group controlId="site_visit_ids" className="mb-3">
              <Select 
                isMulti={true}
                isSearchable={true}
                closeMenuOnSelect={false}
                value={siteVisitIds ? siteVisitIds.map(i => {
                  const s = props.siteVisits.find(obj => obj.id == i);
                  return { value: s.id, label: `${s.id} - ${s.name}` };
                }) : null} 
                onChange={(e) => setSiteVisitIds(e ? e.map(i => i.value) : [])} 
                options={props.siteVisits.map(s => ({value: s.id, label: `${s.id} - ${s.name}`}))}
                isDisabled={formIds.length > 0  || formTypeId}
              placeholder={I18n.t('headers.survey_data_export.select_monitoring_request')}
              />
            </Form.Group>
        </Col>
        <Col md={6}>
            <Form.Group controlId="monitoring_cycle_ids" className="mb-3">
              <Select 
                isMulti={true}
                isSearchable={true}
                closeMenuOnSelect={false}
                value={monitoringCycleIds ? monitoringCycleIds.map(i => {
                  const mc = props.monitoringCycles.find(obj => obj.id == i);
                  return { value: mc.id, label: monitoringCycleFullName(mc) };
                }) : null} 
                onChange={(e) => setMonitoringCycleIds(e ? e.map(i => i.value) : [])} 
                options={props.monitoringCycles.map(i => ({value: i.id, label: monitoringCycleFullName(i)}))}
                isDisabled={false}
                placeholder={I18n.t('headers.survey_data_export.select_monitoring_cycle')}
              />
            </Form.Group>
        </Col>
      </Row>
      <Form.Group className="mb-3" controlid="surveyDataExportForm.buttons">
        <Button disabled={inProgress || !((formTypeId || formIds.length > 0 || siteVisitIds.length > 0) && monitoringCycleIds.length > 0)} variant="outline-primary" onClick={() => handleSubmit()}>{inProgress ? (<><span>Exporting...</span> <Spinner animation="border" size="sm"/></>) : I18n.t("headers.survey_data_export.export")}</Button> &nbsp;
      </Form.Group>
      
    </Form>
  )
}