import React, { Component }  from 'react'
import moment from 'moment'
import axios from 'axios'
import styled from 'styled-components'
import Board from './boards/Board'
import SortableTable from './tables/SortableTable.js'
import MonitoringRequestFilters from './filters/MonitoringRequestFilters'
import SiteVisitForm from './forms/SiteVisitForm.js'
import IpOutputsRequestEmailForm from './forms/IpOutputsRequestEmailForm.js'
import { appConstants, badgeAttributes } from 'utils/constants.js'
import I18n from 'i18n-js/locales.js'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import { CSVLink } from 'react-csv'
import { Tabs, Tab, Modal, Button, Collapse, OverlayTrigger, Tooltip, Badge, Spinner } from 'react-bootstrap'
import { BsBoxArrowDown, BsBoxArrowUp } from 'react-icons/bs'
import Fuse from 'fuse.js'
import { BiLinkExternal } from 'react-icons/bi'
import { RiAlertFill, RiMailCheckLine } from 'react-icons/ri'
import { BsFillLightningFill } from "react-icons/bs";
import { RiLinksFill } from "react-icons/ri";


const NewRequestButton = styled(Button)`
  right: 1.5em;
  position: absolute;
  z-index: 0;
  `;

const SendToPodioButton = styled(Button)`
  right: 26.75em;
  position: absolute;
  z-index: 0;
  min-width: 258px;
  `;

const IpOutputsEmailButton = styled(Button)`
  right: 10em;
  position: absolute;
  z-index: 0;
  min-width: 258px;
  `;

const FiltersButton = styled.div`
  left: .5em;
  top: .1em;
  position: absolute;
  z-index: 0;
  cursor: pointer;
  color: #205492;
  `;

const ExportWrapper = styled(CSVLink)`
  left: 88%;
  top: -6px;
  position: relative;
  z-index: 0;
  `;

const searchOptions = {
  // https://fusejs.io/api/options.html for full list of options
  minMatchCharLength: 3,
  threshold: 0.1,
  distance: 300,
  keys: [
    "name",
    "id",
    "description",
    "beneficiaries",
    "request_justification_details",
    "poc",
  ]
}

export default class TPMDashboard extends Component {

  state = {
    initialData: this.props.initialData.data || [],
    filteredData: this.props.initialData.data || [],
    filters: this.props.initialData.filters,
    tableColumns: this.props.initialData.columns,
    reviewBoard: this.props.initialData.review_board,
    selectionBoard: this.props.initialData.selection_board,
    analysisBoard: this.props.initialData.analysis_board,
    viewModal: false,
    viewIpOutputsEmailModal: false,
    selectedRequestId: '',
    selectedTab: this.props.defaultTab,
    showFilters: true,
    searchTerms: "",
    mapClusters: true,
    podioSending: false,
  }

  componentDidMount() {
    const reviewBoard = this.setBoardData(this.state.reviewBoard, this.state.initialData)
    const selectionBoard = this.setBoardData(this.state.selectionBoard, this.state.initialData)
    const analysisBoard = this.setBoardData(this.state.analysisBoard, this.state.initialData)
    const newState = {
      ...this.state,
      reviewBoard: reviewBoard,
      selectionBoard: selectionBoard,
      analysisBoard: analysisBoard,
    }
    this.setState(newState)
    if (this.props.monitoringRequestId) {
      this.editRequest(this.props.monitoringRequestId)
    }
  }

  refreshData = () => {
    axios.get(appConstants.PLANNING_URL + ".json")
    .then(res => {
      const monitoringRequests = res.data;
      this.setState({
        initialData: monitoringRequests.data,
      }, () => {
        this.filterData(this.state.filters)
      })
    })
    .catch(error => {
      console.log(error);
    })
  }

  // filtering functions
  handleFilterChange = (e, filterBy, clickClear) => {
    const filters = this.state.filters
    let filterValue = null
    if (e && (this.state.filters[filterBy] != e.value || !clickClear)) { filterValue = e.value }
    const newFilters = {
      ...filters,
      [filterBy]: filterValue,
    }
    this.filterData(newFilters)
  }

  handleSearch = (e) => {
    this.setState({
      searchTerms: e.target.value
    }, () => {
        this.filterData(this.state.filters)
    })
  }

  filterData = (filters) => {
    const data = this.state.initialData
    const filterKeys = Object.keys(filters)
    let filteredData = [...data]
    for (let filterKey of filterKeys) {
      if (filters[filterKey]) {
        if (filters[filterKey] == -1) {
          filteredData = filteredData.filter(task => (task[filterKey] == "" || task[filterKey] == null) )
        } else {
          filteredData = filteredData.filter(task => task[filterKey] == filters[filterKey] )
        }
        
      }
    }
    if (this.state.searchTerms.length > 2) {
      const fuse = new Fuse(filteredData, searchOptions);
      const searchResults = fuse.search(this.state.searchTerms);
      filteredData = searchResults.map(task => task.item)
    }

    const reviewBoard = this.setBoardData(this.state.reviewBoard, filteredData)
    const selectionBoard = this.setBoardData(this.state.selectionBoard, filteredData)
    const analysisBoard = this.setBoardData(this.state.analysisBoard, filteredData)
    const newState = {
      ...this.state,
      filteredData: filteredData,
      reviewBoard: reviewBoard,
      selectionBoard: selectionBoard,
      analysisBoard: analysisBoard,
      filters: filters,
    }
    this.setState(newState)
  }

  //  tpm board functions
  setBoardData = (board, data) => {
    const columnKeys = Object.keys(board.columns)
    let newColumns = board.columns
    let newTasks = {}
    for (let key of columnKeys) {
      newColumns[key]["taskIds"] = []
    }
    data.map(task => {
      if (newColumns[task.request_status_id]) {
        task.id = task.id.toString()
        newTasks[task.id] = task
        newColumns[task.request_status_id]["taskIds"].push(task.id)
      }
    })
 
    const newBoard = {
      ...board,
      tasks: newTasks,
      columns: newColumns,
    }
    return newBoard
  }

  dropDisabled = (index, columnId, homeIndex, multidirectional) => {
    if (multidirectional && this.props.allowed.edit_site_visit) {
      return false
    } else if (!this.props.allowed.edit_site_visit) {
      return true
    } else {
      return index < homeIndex
    }
  }

  taskContent = (task) => {
    const lastStatus = task.request_status_id == 'submitted' ? I18n.t('activerecord.attributes.monitoring_request.date_submitted') : 'Status Updated'
    const virtual = task.virtual ? <span id={Math.random()}><i className="fa fa-phone"></i></span> : ''
    const visitPurposeBadge = <Badge pill bg={task.visit_purpose['text'] === 'test' ? undefined : ""} className={badgeAttributes[task.visit_purpose]["class"]}>{badgeAttributes[task.visit_purpose]["text"]}</Badge>
    const siteInactive = !task.ip_site_active ? <span id={Math.random() } className="float-end gold"><RiAlertFill /></span> :""
    const podioLink = task.podio_id ? <span id={Math.random()} className="float-end podio-teal"><RiLinksFill /></span> : task.has_podio_required_fields ? <span id={Math.random()} className="float-end gold"><RiLinksFill /></span> : ""
    const flashReport = task.flash_report_ready ? <span id={Math.random()} className="float-end darkorange"><BsFillLightningFill /></span> : ''
    const completionEmailSent =  task.completion_email_sent ? <span id={Math.random()} className="float-end green"><RiMailCheckLine /></span> : ''
    const ipOutputsRequestSent = (task.request_status_id == 'selected' || task.request_status_id == 'in_data_collection') && task.ip_outputs_request_sent ? <span id={Math.random()} className="float-end purple"><RiMailCheckLine /></span> : ''
    return (
      <div className="task-content">
        <strong><a href='#' onClick={() => {this.editRequest(task.id)}}>{task.id}. {task.name}</a></strong> {virtual} {this.renderLinkedSiteSelector(task.fulcrum_id)}<br/>
        {lastStatus}: {task.date_submitted} &nbsp; {visitPurposeBadge} &nbsp; {siteInactive} &nbsp; {podioLink} &nbsp; {completionEmailSent} &nbsp; {ipOutputsRequestSent} &nbsp; {flashReport}<br/>
      </div>
    )
  }

  renderLinkedSiteSelector = (fulcrum_id) => {
    return (this.props.allowed.edit_site_visit && fulcrum_id && fulcrum_id.length > 0) ? (
      <span><a href={appConstants.FULCRUM_URL + "records/" + fulcrum_id} target="_blank" rel="noopener noreferrer" key={fulcrum_id}><BiLinkExternal /></a>&nbsp;</span>
    ) : ""
  }

  renderLinkedRecords = (records) => {
    return (this.props.edit_site_visit) ? (
      <>
        {records.map((record) => 
          <OverlayTrigger 
            key={record} 
            placement='top'
            overlay={
              <Tooltip id={`tooltip-${record}`}>
                {record}
              </Tooltip>
            }
          >
            <a href={appConstants.FULCRUM_URL + "records/" + record} target="_blank" rel="noopener noreferrer" key={record}><BiLinkExternal /></a>
          </OverlayTrigger>
        )}
     </>
     ) : ""
  }

  requestModalLink = (cell, row) => {
    return (
      cell["column"]["id"] === "name"
        ? <a href='#' onClick={() => {this.editRequest(row["original"]["id"])}}>{row["original"]["name"]}</a>
        : cell.render('Cell') 
    )
  }

  persistStatus(id, status) {
    axios.defaults.headers.common["X-CSRF-TOKEN"] = this.props.authenticityToken;
    axios.put(`${appConstants.MONITORING_EVENT_URL}${id}`, {"request_status": status, "date_submitted": moment()} )
    .then(res => {
      this.refreshData()
    })
    .catch(error => {
      console.log(error)
    })
  }

  onReviewDragStart = start => {
    this.onDragStart(start,"reviewBoard")
  }

  onSelectionDragStart = start => {
    this.onDragStart(start,"selectionBoard")
  }

  onAnalysisDragStart = start => {
    this.onDragStart(start,"analysisBoard")
  }

  onReviewDragEnd = result => {
    this.onDragEnd(result,"reviewBoard")
  }

  onSelectionDragEnd = result => {
    this.onDragEnd(result,"selectionBoard")
  }

  onAnalysisDragEnd = result => {
    this.onDragEnd(result,"analysisBoard")
  }

  onDragStart = (start, board) => {
    const homeIndex = this.state[board].columnOrder.indexOf(start.source.droppableId);
  
    const draggingState = {
      ...this.state[board],
      homeIndex: homeIndex
    }
    this.setState({[board]: draggingState})
  }

  onDragEnd = (result, board) => {
    const droppedState = {
      ...this.state[board],
      homeIndex: null
    }
    this.setState({[board]: droppedState})

    const { destination, source, draggableId } = result;

    // Moving aborted
    if(!destination) {
      return;
    }

    //Moving to original spot
    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    const start = this.state[board].columns[source.droppableId]
    const finish = this.state[board].columns[destination.droppableId]

    // Moving within same column 
    if(start === finish) {
      const newTaskIds = Array.from(start.taskIds)
      newTaskIds.splice(source.index, 1)
      newTaskIds.splice(destination.index, 0, draggableId)

      const newColumn = {
        ...start,
        taskIds: newTaskIds,
      };
    
      const newState = {
        ...this.state[board],
        columns: {
          ...this.state[board].columns,
          [newColumn.id]: newColumn,
        },
      };
    
      this.setState({[board]: newState})
      return
    }

    // Moving from one list to another
    const startTaskIds = Array.from(start.taskIds);
    startTaskIds.splice(source.index, 1);
    const newStart = {
      ...start,
      taskIds: startTaskIds,
    };

    const finishTaskIds = Array.from(finish.taskIds);
    finishTaskIds.splice(destination.index, 0, draggableId);
    const newFinish = {
      ...finish,
      taskIds: finishTaskIds,
    };

    const newState = {
      ...this.state[board],
      columns: {
        ...this.state[board].columns,
        [newStart.id]: newStart,
        [newFinish.id]: newFinish,
      },
    };
    this.setState({[board]: newState})
    this.persistStatus(draggableId, destination.droppableId)
  }

  // tpm calendar functions
  getEventColor = (e) => {
    const style = {
      backgroundColor: e.hold ? '#c13851' : ''
    }
    return {
      style: style
    }
  }

  // modal/form functions
  editRequest = (taskId) => {
    this.setState({
      selectedRequestId: taskId,
      viewModal: true
    })
  }

  newRequest = () => {
    this.setState({
      viewModal: true,
      selectedRequestId: ''
    })
  }

  ipOutputsEmail = () => {
    this.setState({
      viewIpOutputsEmailModal: true,
    })
  }

  closeModal = () => {
    this.setState({
      viewModal: false,
      viewIpOutputsEmailModal: false,
    })
  }

  sendToPodio = async () => {
    this.setState({podioSending: true})
    axios.defaults.headers.common["X-CSRF-TOKEN"] = this.props.authenticityToken;
    await axios.post(`${appConstants.SEND_TO_PODIO_URL}`)
    .then(res => {
      this.setState({podioSending: false})
      this.refreshData()
    })
    .catch(error => {
      this.setState({podioSending: false})
      this.refreshData()
      console.log(error)
    })
  }

  renderMonitoringRequestModalContent() {
    return (
      <SiteVisitForm col={6}
        allowed={this.props.allowed}
        id={this.state.selectedRequestId}
        tabKey={this.props.tabKey}
        wfpId={this.props.wfpId}
        authenticityToken={this.props.authenticityToken}
        resourceTypeIds={this.props.resourceTypeIds}
        ipOutputsExportHeaders={this.props.ipOutputsExportHeaders}
        refreshData={this.refreshData}
        closeModal={this.closeModal} />
    )
  }

  renderRequestModal() {
    return (
      <Modal
        show={this.state.viewModal}
        onHide={this.closeModal}
        keyboard={true}
        fullscreen={true}>
        <Modal.Header closeButton>
          <Modal.Title>{`${I18n.t('activerecord.attributes.monitoring_request.monitoring_event')} ${this.state.selectedRequestId}` }</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.renderMonitoringRequestModalContent()}
        </Modal.Body>
      </Modal>
    )
  }

  renderIpOutputsEmailModal() {
    return (
      <Modal
        show={this.state.viewIpOutputsEmailModal}
        onHide={this.closeModal}
        keyboard={true}
        size={'xl'}>
        <Modal.Header closeButton>
          <Modal.Title>{I18n.t('monitoring_request.send_ip_outputs_request_email')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <IpOutputsRequestEmailForm
            authenticityToken={this.props.authenticityToken}
            isModal={true}
            closeModal={this.closeModal}
            visitsUrl={appConstants.IP_OUTPUTS_MISSING_VISITS_URL}
            refreshData={this.refreshData}
          />
        </Modal.Body>
      </Modal>
    )
  }

  renderSendToPodioButton() {
    return (this.props.allowed.send_to_podio && this.state.selectedTab == "selection_board") ?
    (
      <SendToPodioButton variant="outline-secondary" onClick={() => this.sendToPodio()} disabled={this.state.podioSending}>
        {this.state.podioSending ? <><Spinner animation="border" size="sm" /> Sending...</> : I18n.t('navigation.send_visits_to_podio')}
      </SendToPodioButton>
    ) : ""
  }

  renderNewRequestButton() {
    return (this.props.allowed.create_site_visit) ?
    (
      <NewRequestButton variant="outline-primary" onClick={() => this.newRequest()}>{I18n.t('monitoring_request.new')}</NewRequestButton>
    ) : ""
  }

  renderIpOutputsEmailButton() {
    return (this.props.allowed.create_site_visit && (this.state.selectedTab == "selection_board" || this.state.selectedTab == "board")) ?
    (
      <IpOutputsEmailButton variant="outline-success" onClick={() => this.ipOutputsEmail()}>{I18n.t('monitoring_request.send_ip_outputs_request_email')}</IpOutputsEmailButton>
    ) : ""
  }

  renderFiltersButton() {
    return (
      <FiltersButton 
        onClick={() => this.setState({showFilters: !this.state.showFilters})} 
        aria-controls="collapse-filters" 
        aria-expanded={this.state.showFilters}>
          <h4>{this.state.showFilters ? <BsBoxArrowUp /> : <BsBoxArrowDown />}</h4>
      </FiltersButton>
    ) 
  }

  renderSelectionBoard() {
    return (this.state.selectedTab == "selection_board") ?
      (
        <Board 
          onDragStart={this.onSelectionDragStart}
          onDragEnd={this.onSelectionDragEnd}
          dropDisabled={this.dropDisabled}
          taskContent={this.taskContent}
          initialData={this.state.selectionBoard}
        />
      ) : ""
  }

  renderAnalysisBoard() {
    return (this.state.selectedTab == "analysis_board") ?
      (
        <Board 
          onDragStart={this.onAnalysisDragStart}
          onDragEnd={this.onAnalysisDragEnd}
          dropDisabled={this.dropDisabled}
          taskContent={this.taskContent}
          initialData={this.state.analysisBoard}
        />
      ) : ""
  }

  renderReviewBoard() {
    return (this.state.selectedTab == "board") ?
      (
        <Board 
          onDragStart={this.onReviewDragStart}
          onDragEnd={this.onReviewDragEnd}
          dropDisabled={this.dropDisabled}
          taskContent={this.taskContent}
          initialData={this.state.reviewBoard}
        />
      ) : ""
  }

  renderList() {
    return (this.state.selectedTab == "list") ?
      (
        <SortableTable
          data={this.state.filteredData}
          columns={this.state.tableColumns}
          useRenderFunction={true}
          cellContent={this.requestModalLink}
        />
      ) : ""
  }

  renderCalendar() {
    return (this.state.selectedTab == "calendar") ?
      (
        <Calendar
          localizer={momentLocalizer(moment)}
          events={this.state.filteredData}
          startAccessor="unformatted_start_date"
          endAccessor="unformatted_start_date"
          drillDownView="agenda"
          onSelectEvent={this.editRequest}
          style={{ height: 500 }}
          popup={true}
          eventPropGetter={this.getEventColor}
          step={360}
          timeslots={24}
          defaultView={'week'}
        />
      ) : ""
  }

  render() {
    return (
      <>
        {this.renderRequestModal()}
        {this.renderIpOutputsEmailModal()}
        <div>
          <Collapse in={this.state.showFilters}>
            <div id="collapse-filters">
              <MonitoringRequestFilters handleFilterChange={this.handleFilterChange} handleSearch={this.handleSearch} includedFilters={this.state.filters} />
            </div>
          </Collapse>
          {this.renderFiltersButton()}
        </div>
        <div>
          {this.renderSendToPodioButton()}
          {this.renderIpOutputsEmailButton()}
          {this.renderNewRequestButton()}
          <Tabs defaultActiveKey={this.state.selectedTab} id="listTabs" onSelect={(selectedKey) => this.setState({selectedTab: selectedKey})}>
            { this.props.allowed.selection_board ?
              (
                <Tab eventKey="selection_board" title={I18n.t("headers.monitoring_request.selection_board")}>
                  <br/>
                  {this.renderSelectionBoard()}
                </Tab>
              ) : ""
            }
            { this.props.allowed.review_board ?
              (
                <Tab eventKey="board" title={I18n.t("headers.monitoring_request.board")}>
                  <br/>
                  {this.renderReviewBoard()}
                </Tab>
              ) : ""
            }
            { this.props.allowed.analysis_board ?
              (
                <Tab eventKey="analysis_board" title={I18n.t("headers.monitoring_request.analysis_board")}>
                  <br/>
                  {this.renderAnalysisBoard()}
                </Tab>
              ) : ""
            }
            { this.props.allowed.visit_list ?
              (
                <Tab eventKey="list" title={I18n.t("headers.monitoring_request.list")}>
                  <br/>
                  <ExportWrapper data={this.state.filteredData} filename={I18n.t("headers.monitoring_request.planning_export_filename")} headers={this.props.exportHeaders}>{I18n.t("headers.monitoring_request.export")}</ExportWrapper>
                  {this.renderList()}
                </Tab>
              ) : ""
            }
            { this.props.allowed.visit_calendar ?
              (
                <Tab eventKey="calendar" title={I18n.t("headers.monitoring_request.calendar")}>
                  <br/>
                  {this.renderCalendar()}
                </Tab>
              ) : ""
            }
          </Tabs>
        </div>
      </>
    )
  }
}

